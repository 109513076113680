import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Slider from 'react-slick';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CountryCard from './components/CountryCard';
import countries from '../../_mock/country';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CountryList = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Divider />
      <Box id="countries" component="section" sx={{ py: 3 }}>
        <Typography variant="h3" textAlign="center" sx={{ fontWeight: 'bold' }}>
          Countries For <span style={{ color: '#f57c00' }}> Study Abroad </span>
        </Typography>


        <Divider
          sx={{
            width: '50%',
            margin: '10px auto',
            borderBottomWidth: 3,
            backgroundColor: '#1976d2',
            borderRadius: '5px',
          }}
        />

        <Box sx={{ mt: 5 }}>
          <Slider {...settings}>
            {countries.map((country) => (
              <Box key={country.id} sx={{ px: 2 }}>
                <CountryCard country={country} />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: 'block',
        color: '#333',
        padding: '10px',
        position: 'absolute',
        top: '50%',
        right: '-40px',
        zIndex: 1,
        cursor: 'pointer',
        transform: 'translateY(-50%)',
      }}
    >
      <ChevronRightIcon style={{ fontSize: '48px' }} />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        display: 'block',
        color: '#333',
        padding: '10px',
        position: 'absolute',
        top: '50%',
        left: '-40px',
        zIndex: 1,
        cursor: 'pointer',
        transform: 'translateY(-50%)',
      }}
    >
      <ChevronLeftIcon style={{ fontSize: '48px' }} />
    </div>
  );
};

export default CountryList;
