import React from 'react'
import CountUp from 'react-countup';
import { Box, Button, Typography} from '@mui/material'
import { Link } from 'react-router-dom';
import Aboutimg from '../../Assets/about-us-dunya.avif'

function Aboutus() {
  return (
    <div>
      <section className='company-intro py-3'>
        <div className='container'>
            <div className='row align-items-center'>
                <div className='col-md-7 py-5 mb-4'>
                <Typography variant='h3' sx={{fontFamily: 'Public Sans'}} className='mb-4'>Your Trusted Study Abroad <br />Consultants</Typography>
                    <p>
                    At Dunya Course Finder, we are dedicated study abroad consultants committed to helping students achieve their academic dreams. Our education consultancy specializes in finding the perfect course in prestigious foreign universities. With our course finder tool, students can explore programs tailored to their interests and career goals. We also offer support for securing study abroad scholarships, ensuring financial constraints don't hinder your journey. As a premier study abroad consultancy, our experienced education consultants provide personalized assistance, from applications to visa guidance. Trust Dunya Course Finder to be your partner in making your study abroad aspirations a reality.
                    </p>
                    <div className="row d-flex justify-content-center align-items-center stats mb-4">
                <div className="col-md-4 col-4">
                <CountUp
                    start={0}
                    end={25000}
                    duration={3}
                    className='counter stat-1'
                />
                <h3>Courses</h3>
                  
                </div>
                <div className="col-md-4 col-4">
                <CountUp
                    start={0}
                    end={500}
                    duration={3}
                    className='counter stat-2'
                />
                <h3>Satisfied Clients</h3>
                </div>
                <div className="col-md-4 col-4 extra" style={{marginRight: '-28px', borderRight: 'none'}}>
                <CountUp
                    start={0}
                    end={100}
                    duration={3}
                    className='counter stat-3'
                />
                <h3>Client Satisfaction</h3>
                </div>
              </div>
              <Box component="div">
                <Link to='/details'>
                <Button
                  variant="contained"
                  size="large"
                  sx={{ minWidth: 200, width: { xs: 1, sm: 'max-content' }, py: 3.5 }}
                >
                  Search For Courses
                </Button>
                </Link>
              </Box>
                </div>
                <div className='col-md-5'>
                    <img src={Aboutimg} className='img-fluid' alt="study-abroad-consultants"/>
                </div>
            </div>
        </div>
      </section>
    </div>
  )
}

export default Aboutus
