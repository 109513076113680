  import { faker } from '@faker-js/faker';

  const offices = [
    {
      id: faker.datatype.uuid(),
      city: 'Head',
      email: 'dha.lhe@dunyaconsultants.com',
      phone: '+92 300-167-1947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: '17H, 1st Floor, DHA Phase 1, Lahore, Punjab 54000',
      mapUrl: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13609.942189610572!2d74.3954366!3d31.4833351!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391905fe8cabf0c9%3A0xdcbaeda8e283b539!2sDunya%20Consultants%20DHA%20Lahore%20%7C%20Study%20Abroad%20Educational%20Consultant.!5e0!3m2!1sen!2s!4v1726127400258!5m2!1sen!2s'
    },
    {
      id: faker.datatype.uuid(),
      city: 'JT Lahore',
      email: 'jt.lhe@dunyaconsultants.com',
      phone: '+92 300-827-1947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: 'Dunya Consultants Office 95 G1 Market Johar Town, Lahore',
      mapUrl: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6805.436122948191!2d74.280027!3d31.476940999999997!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190390d0dc3437%3A0x48eadfb15441a579!2sDunya%20Consultants%2C%20Johar%20Town%20Lahore!5e0!3m2!1sen!2sus!4v1726143506482!5m2!1sen!2sus'

    },
    {
      id: faker.datatype.uuid(),
      city: 'Islamabad',
      email: 'isb@dunyaconsultants.com',
      phone: '+92 333-777-5458',
      time: 'Mon-Sat: 10AM – 6PM',
      address: 'Mezz-3 ATS Centre, Fazal e Haq Road, Blue Area Islamabad',
      mapUrl: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13273.56222568414!2d73.0863556!3d33.7247156!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbfac1292112b%3A0x7e7532cebf8714ea!2sDunya%20Consultants%2C%20Islamabad!5e0!3m2!1sen!2s!4v1726127188949!5m2!1sen!2s'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Karachi',
      email: 'huzifa.khi@dunyaconsultants.com',
      phone: '+92 337-062-1947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: '05-C Prime Point Building, Main, 2 Khayaban-e-Ittehad Road, phase Defence Housing Authority, Karachi',
      mapUrl:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14483.886659746026!2d67.073896!3d24.8306426!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa49ac139a409b0b3%3A0xa6e2b457493cc1e3!2sDunya%20Consultants%2C%20Karachi!5e0!3m2!1sen!2s!4v1726127124203!5m2!1sen!2s"'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Sargodha',
      email: 'sgd@dunyaconsultants.com',
      phone: '+92 325-163-1947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: 'Dunya Consultants Opposite Cheema Heart Centre, Sargodha',
      mapUrl: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13522.353038951675!2d72.6815117!3d32.0803834!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3921779e48349883%3A0x99d406957c4d75b!2sDunya%20Consultants!5e0!3m2!1sen!2s!4v1726123353021!5m2!1sen!2s',
    },
    {
      id: faker.datatype.uuid(),
      city: 'Gujrat',
      email: 'sgd@dunyaconsultants.com',
      phone: '+92 300-105-1947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: 'Beside KFC, Service Morh, Road, G.T Gujrat',
      mapUrl: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13446.161589246565!2d74.0485381!3d32.5917792!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9604d3323b4ad81%3A0x54fdd7b5b835a3e5!2sDunya%20Consultants%20Gujrat!5e0!3m2!1sen!2s!4v1726143675926!5m2!1sen!2s'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Faisalabad',
      email: 'fsd@dunyaconsultants.com',
      phone: '+92 345-662-8487',
      time: 'Mon-Sat: 10AM – 6PM',
      address: '66-Mezanine floor, Center Point Plaza, Koh-i-Noor City, Jaranwala Road, Faisalabad',
      mapUrl:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13620.361268908824!2d73.1166014!3d31.4116376!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3922697c12bc0a01%3A0xe6e330ef04cc31df!2sDunya%20Consultants%20Faisalabad!5e0!3m2!1sen!2s!4v1726127032986!5m2!1sen!2s'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Gujranwala',
      email: 'grw@dunyaconsultants.com',
      phone: '+92 332-999-1947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: 'Dunya consultants, peoples colony underpass, Gujranwala',
      mapUrl:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13513.769308648998!2d74.1984483!3d32.1383599!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391f2b27c580f995%3A0x88575b0ff2162dc9!2sDunya%20Consultants%20Gujranwala!5e0!3m2!1sen!2s!4v1726127078409!5m2!1sen!2s"'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Sialkot',
      email: '',
      phone: '+92 328-6111947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: '2nd Floor, Bank Islami, Cantt Plaza, Aziz Bhati Shaheed Road, Sadar Bazar Saddar Cantt Sialkot Cantonment, Sialkot',
      mapUrl:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13457.411671350581!2d74.559284!3d32.51672!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfa0f9fbb6589fc5%3A0x470924558baca092!2sDunya%20Consultants%20Sialkot!5e0!3m2!1sen!2sus!4v1726135333987!5m2!1sen!2sus'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Bahawalpur',
      email: '',
      phone: '+92 300-173-1947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: '2nd Floor, Bank Islami, Cantt Plaza, Aziz Bhati Shaheed Road, Sadar Bazar Saddar Cantt Sialkot Cantonment, Sialkot',
      mapUrl:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13457.411671350581!2d74.559284!3d32.51672!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfa0f9fbb6589fc5%3A0x470924558baca092!2sDunya%20Consultants%20Sialkot!5e0!3m2!1sen!2sus!4v1726135333987!5m2!1sen!2sus'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Mian Channu',
      email: 'isb@dunyaconsultants.com',
      phone: '+92 300-984-1947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: '1st Floor, Moti Plaza, Allama Iqbal Road, Mian Channu',
      mapUrl:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13759.409634414107!2d72.3533813!3d30.4402856!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x392335a487ab14c7%3A0xb414f055ca8d8cbf!2sDunya%20Consultants%20Mian%20Channu!5e0!3m2!1sen!2s!4v1726126858303!5m2!1sen!2s">'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Mandi Bahaudin',
      email: '',
      phone: '+92 321-300-1947',
      time: 'Mon-Sat: 10AM – 6PM',
      address: 'Dunya Consultants Punjab Center Mandi Bahauddin',
      mapUrl:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13447.728887546094!2d73.4807148!3d32.5813316!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391f7dd25643a041%3A0xc783051e5aaf0483!2sDunya%20Consultants%20Mandi%20Bahauddin!5e0!3m2!1sen!2s!4v1726126957608!5m2!1sen!2s'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Saudi Arabia',
      email: '',
      phone: '+966 50 851 0785',
      time: 'Mon-Thu: 10AM – 5PM',
      address: 'above topten, Engineering Square, Makarona Street, 2nd floor, office 27, Jeddah 23447, Saudi Arabia',
      mapUrl:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14840.116848291129!2d39.1892538!3d21.5847828!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3d138aa8b4257%3A0x5f29cc0f3455cb2a!2sDunya%20Consultants%20Jeddah%20KSA!5e0!3m2!1sen!2s!4v1726141397362!5m2!1sen!2s'
    },
    {
      id: faker.datatype.uuid(),
      city: 'Turkey',
      email: '',
      phone: '+90 505 305 8047',
      time: 'Mon-Sat: 10AM – 6PM',
      address: 'Ataköy 7-8-9-10. Kısım Mah. Çobançeşme E-5 Yan Yol Cad. Ataköy Towers A Blok Apt. No: 20/1 Bakırköy / İstanbul',
      mapUrl:'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12046.096877133454!2d28.834234!3d40.991898!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caa3672c8c4aeb%3A0xa4cf51dced9f3230!2sAtak%C3%B6y%20Towers!5e0!3m2!1sen!2sus!4v1726142403811!5m2!1sen!2sus'
    },

  ];

  export default offices;
