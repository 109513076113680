import React from 'react';
import { Box, Grid, Typography, Divider, Stack } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { styled } from '@mui/material/styles';
import Logo from '../logo';

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.grey[900],
}));

const Footer = () => (
  <Box component="footer" sx={{ marginTop: 'auto' }}>
    <Divider />
    <Box sx={{ py: 3, px: { xs: 2, sm: 3 } }}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1.5}
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box>
          <Logo />
        </Box>
        <Box>
          <Typography variant="subtitle2" textAlign="center">
            &copy; Copyright {new Date().getFullYear()} by{' '}
            <StyledLink href="https://xcontechnologies.com/" target="blank">
              Xcon technologies.com
            </StyledLink>
          </Typography>
        </Box>
      </Stack>
    </Box>
  </Box>
);

export default Footer;
