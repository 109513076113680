import React from 'react';
import gif from '../../Assets/ezgif.com-animated-gif-maker.gif';

const DuniyaGif = () => {
  return (
    <>
      <div style={{ margin: '2% 0' }}>
        <img
          src={gif}
          alt="Study abroad"
          // style={{
          //   width: '100%',
          //   height: 'auto',
          // }}
        />
      </div>
    </>
  );
};

export default DuniyaGif;